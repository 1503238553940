import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { facilitySchema } from "@src/appV2/Facilities/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import { type QueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  shiftRateNegotiationSchema,
  WorkplaceShiftRateNegotiability,
} from "../RateNegotiation/types";

const openShiftsRequestSchema = z
  .object({
    dateFilter: z
      .object({
        start: z.string(),
        end: z.string(),
      })
      .optional(),
    coordinates: z.tuple([z.number(), z.number()]).optional(),
    distance: z.number().optional(),
    qualification: z.string().optional(),
    facilityId: z.string().optional(),
    specialities: z
      .object({
        hasSedationExperience: z.boolean(),
        hasTrayAssemblyExperience: z.boolean(),
      })
      .optional(),
    id: z.array(z.string()).optional(),
  })
  .optional();

const openShiftsResponseSchema = z.object({
  openAgentShifts: z.array(
    shiftSchema.extend({
      filtered: z.boolean().optional(),
      // facilityNotes actually comes within the facility object
      facilityNotes: z.undefined(),
      facility: facilitySchema.extend({
        rating: z.object({
          count: z.number(),
          value: z.number(),
        }),
        rateNegotiation: z.nativeEnum(WorkplaceShiftRateNegotiability).nullish(),
      }),
      rateNegotiation: shiftRateNegotiationSchema.optional(),
    })
  ),
});

export type OpenShiftsRequestParams = z.infer<typeof openShiftsRequestSchema>;
export type OpenShiftsResponse = z.infer<typeof openShiftsResponseSchema>;

export type OpenShiftsQueryOptions = UseGetQueryOptions<OpenShiftsResponse>;

export const GET_OPEN_SHIFTS_PATH = "/calendar/openShifts";
export const GET_OPEN_SHIFTS_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_OPEN_SHIFTS_PATH}`;

export async function invalidateOpenShifts(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [GET_OPEN_SHIFTS_URL] });
}

export function useOpenShifts(
  params?: OpenShiftsRequestParams,
  options: OpenShiftsQueryOptions = {}
) {
  return useGetQuery({
    url: GET_OPEN_SHIFTS_URL,
    queryParams: params,
    requestSchema: openShiftsRequestSchema,
    responseSchema: openShiftsResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_BY_DATE_FAILURE,
    },
    ...options,
  });
}
